export const osm = [
	//O
	[-5, 1, 0],
	[-5, 2, 0],
	[-5, 3, 0],
	[-5, 4, 0],
	[-5, 5, 0],
	[-4, 5, 0],
	[-4, 1, 0],
	[-3, 3, 0],
	[-3, 2, 0],
	[-3, 5, 0],
	[-3, 1, 0],
	[-3, 4, 0],
	//s
	[-1, 3, 0],
	[-1, 1, 0],
	[-1, 4, 0],
	[-1, 5, 0],
	[0, 5, 0],
	[1, 3, 0],
	[1, 1, 0],
	[1, 2, 0],
	[1, 5, 0],
	[0, 1, 0],
	[0, 3, 0],
	//m
	[3, 2, 0],
	[3, 1, 0],
	[3, 3, 0],
	[3, 4, 0],
	[4, 5, 0],
	[3, 5, 0],
	[5, 3, 0],
	[5, 2, 0],
	[5, 5, 0],
	[5, 1, 0],
	[5, 4, 0],
	[6, 5, 0],
	[7, 3, 0],
	[7, 5, 0],
	[7, 1, 0],
	[7, 2, 0],
	[7, 4, 0],
];
export const border = [
	[-6, 1, 0],
	[-5, 1, 0],
	[-4, 1, 0],
	[-3, 1, 0],
	[-2, 1, 0],
	[-1, 1, 0],
	[-0, 1, 0],
	[8, 1, 0],
	[7, 1, 0],
	[6, 1, 0],
	[5, 1, 0],
	[4, 1, 0],
	[3, 1, 0],
	[2, 1, 0],
	[1, 1, 0],
	//
	[-6, 7, 0],
	[-5, 7, 0],
	[-4, 7, 0],
	[-3, 7, 0],
	[-2, 7, 0],
	[-1, 7, 0],
	[-0, 7, 0],
	[8, 7, 0],
	[7, 7, 0],
	[6, 7, 0],
	[5, 7, 0],
	[4, 7, 0],
	[3, 7, 0],
	[2, 7, 0],
	[1, 7, 0],
];
export const randomTexture = "wood dirt wood log grass dirt wood".split(" ");
